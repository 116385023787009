import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserActionPermissionValueFragment = { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string };

export type UserActionPermissionsFragment = { __typename?: 'UserActionPermissions', changeOrgSettings: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, manageOrgIntegrations: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, manageBilling: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, inviteOthers: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, createContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, manageLibrary: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, assignContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, unassignContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, updateAutomations: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, verifySkills: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, viewReporting: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, sendMessages: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, editTrainees: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, deactivateTrainees: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, managePremiumContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, promoteTrainees: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, markAsComplete: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, viewAllSubmittedTaskLists: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string } };

export type UserActionPermissionsQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type UserActionPermissionsQuery = { __typename?: 'Query', UserActionPermissions: { __typename?: 'UserActionPermissions', changeOrgSettings: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, manageOrgIntegrations: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, manageBilling: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, inviteOthers: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, createContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, manageLibrary: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, assignContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, unassignContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, updateAutomations: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, verifySkills: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, viewReporting: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, sendMessages: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, editTrainees: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, deactivateTrainees: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, managePremiumContent: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, promoteTrainees: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, markAsComplete: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string }, viewAllSubmittedTaskLists: { __typename?: 'UserActionPermissionValue', hasPermission: boolean, reason: string } } };

export const UserActionPermissionValueFragmentDoc = gql`
    fragment UserActionPermissionValue on UserActionPermissionValue {
  hasPermission
  reason
}
    `;
export const UserActionPermissionsFragmentDoc = gql`
    fragment UserActionPermissions on UserActionPermissions {
  changeOrgSettings {
    ...UserActionPermissionValue
  }
  manageOrgIntegrations {
    ...UserActionPermissionValue
  }
  manageBilling {
    ...UserActionPermissionValue
  }
  inviteOthers {
    ...UserActionPermissionValue
  }
  createContent {
    ...UserActionPermissionValue
  }
  manageLibrary {
    ...UserActionPermissionValue
  }
  assignContent {
    ...UserActionPermissionValue
  }
  unassignContent {
    ...UserActionPermissionValue
  }
  updateAutomations {
    ...UserActionPermissionValue
  }
  verifySkills {
    ...UserActionPermissionValue
  }
  viewReporting {
    ...UserActionPermissionValue
  }
  sendMessages {
    ...UserActionPermissionValue
  }
  editTrainees {
    ...UserActionPermissionValue
  }
  deactivateTrainees {
    ...UserActionPermissionValue
  }
  managePremiumContent {
    ...UserActionPermissionValue
  }
  promoteTrainees {
    ...UserActionPermissionValue
  }
  markAsComplete {
    ...UserActionPermissionValue
  }
  viewAllSubmittedTaskLists {
    ...UserActionPermissionValue
  }
}
    ${UserActionPermissionValueFragmentDoc}`;
export const UserActionPermissionsDocument = gql`
    query UserActionPermissions($userId: Int!) {
  UserActionPermissions(userId: $userId) {
    ...UserActionPermissions
  }
}
    ${UserActionPermissionsFragmentDoc}`;

/**
 * __useUserActionPermissionsQuery__
 *
 * To run a query within a React component, call `useUserActionPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActionPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActionPermissionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserActionPermissionsQuery(baseOptions: Apollo.QueryHookOptions<UserActionPermissionsQuery, UserActionPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserActionPermissionsQuery, UserActionPermissionsQueryVariables>(UserActionPermissionsDocument, options);
      }
export function useUserActionPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserActionPermissionsQuery, UserActionPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserActionPermissionsQuery, UserActionPermissionsQueryVariables>(UserActionPermissionsDocument, options);
        }
export type UserActionPermissionsQueryHookResult = ReturnType<typeof useUserActionPermissionsQuery>;
export type UserActionPermissionsLazyQueryHookResult = ReturnType<typeof useUserActionPermissionsLazyQuery>;
export type UserActionPermissionsQueryResult = Apollo.QueryResult<UserActionPermissionsQuery, UserActionPermissionsQueryVariables>;